<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getRequestsList"
  >
    <template #title>Commission Cashout Requests</template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
        @getSelectedDate="setCreateDateFilter"
        inputLabel="Request Create Date"
        inputPlaceholder="2023-12-06"
        inputId="CreateDateOfRequest"
      ></custom-date-picker>
      <search-input
        v-model="requestsListFilters.fullName"
        inputId="fullNameFilter"
        inputLabel="Search By User Name"
        inputPlaceholder="s y"
      ></search-input>
      <search-input
        v-model="requestsListFilters.sumTrade"
        inputId="sumTradeFilter"
        inputLabel="Sum amount of trade"
        inputPlaceholder="200"
      ></search-input>
      <search-input
        v-model="requestsListFilters.userName"
        inputId="userNameFilter"
        inputLabel="Username"
        inputPlaceholder="farham"
      ></search-input>
    </template>
    <custom-table
      v-if="requestsList"
      customIcon="CheckSquareIcon"
      customIconClass="text-success"
      :pageNumber="requestsListFilters.pageNumber"
      :count="requestsListFilters.count"
      :totalCount="totalCount"
      :tableItems="requestsList"
      :tableColumns="myTableColumns"
      :customCallbackFunction="getConfirmationToApprove"
      @changePage="changePage"
    >
      <template #actions="data">
        <b-button
          v-if="!data.item.isPayed"
          variant="transparent"
          type="button"
          @click="getConfirmationToApprove(data.item)"
        >
          <feather-icon
            icon="ExternalLinkIcon"
            class="text-primary"
            size="20"
          />
        </b-button>
      </template>
      <template #isPayed="data">
        <b-badge :variant="data.item.isPayed ? 'success' : 'warning'">
          {{ data.item.isPayed ? "Payed" : "Pending" }}
        </b-badge>
      </template>
    </custom-table>
    <cashout-confirmation-modal
      :isLoading="isLoading"
      :tempRequest="tempRequest"
      :okCallback="sendConfirmRequest"
    ></cashout-confirmation-modal>
  </page-wrapper>
</template>

<script>
export default {
  title: "Commission Cashout Requests List",
  mounted() {
    this.getRequestsList();
  },
  data() {
    return {
      isLoading: false,
      requestsListFilters: {
        pageNumber: 1,
        count: 10,
        userInviteLinkId: null,
        id: null,
        createDate: null,
        fullName: null,
        sumTrade: null,
        userName: null,
        justAvaliable: null,
        justAvaliableForCommission: null,
      },
      totalCount: null,
      requestsList: null,
      myTableColumns: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "fullName",
          label: "User Fullname",
          formatter: (value) => value.trim() || "-",
        },
        {
          key: "createDate",
          label: "Request Create Date",
          formatter: (value) => new Date(value).toLocaleDateString("en-US"),
        },
        {
          key: "price",
          label: "Requested Amount",
          formatter: (value) => {
            let priceValue = 0;
            if (value && +value > 0) {
              priceValue = value;
            }
            return `${priceValue} $`;
          },
        },
        {
          key: "isPayed",
          label: "Status",
          formatter: (value) => {
            return value ? "Payed" : "Pending";
          },
        },
        {
          key: "code",
          label: "Code",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      tempRequest: null,
    };
  },
  methods: {
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
        .post(
          `UserInviteLink/GetRequestListForAdminComissionExportExel`,
          null,
          {
            responseType: "blob",
            Authorization: userToken,
          }
        )
        .then((resp) => {
          _this.overlay = false;
          saveAs(resp.data, "ComissionCashoutRequestList.xlsx");
        })
        .catch((error) => {
          _this.overlay = false;
          console.log(error);
        });
    },
    isRenderingApproveButton(request) {
      if (request.isPayed) {
        return false;
      }
      return true;
    },
    async sendConfirmRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let confirmACashoutRequest = new ConfirmCommissionCashoutRequest(_this);
        confirmACashoutRequest.setRequestParamDataObj({
          id: _this.tempRequest.id,
        });
        await confirmACashoutRequest.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("confirm-a-cashout-modal");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Confirmed!",
                icon: "CheckIcon",
                variant: "success",
                text: "Cashout Request Confirmed Successfully.",
              },
            });
            _this.getRequestsList();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    getConfirmationToApprove(request) {
      this.tempRequest = JSON.parse(JSON.stringify(request));
      this.$bvModal.show("confirm-a-cashout-modal");
    },
    setCreateDateFilter(date) {
      this.requestsListFilters.createDate = date;
    },
    changePage(pageNumber) {
      this.requestsListFilters.pageNumber = pageNumber;
    },
    async getRequestsList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getCashoutRequestsForAdmin =
          new GetCommissionCashoutRequestsForAdmin(_this);
        getCashoutRequestsForAdmin.setRequestParamDataObj(
          _this.requestsListFilters
        );
        await getCashoutRequestsForAdmin.fetch((res) => {
          _this.totalCount = res.total;
          _this.requestsList = res.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    CustomDatePicker,
    CashoutConfirmationModal,
    SearchInput,
    CustomTable,
    BButton,
    BBadge,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CashoutConfirmationModal from "@/views/components/Referral/CashoutConfirmationModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  GetCommissionCashoutRequestsForAdmin,
  ConfirmCommissionCashoutRequest,
} from "@/libs/Api/UserInviteLink";
import axiosIns from "@/libs/axios";
import { saveAs } from "file-saver";
import { getUserData } from "@/auth/utils";
import { BButton, BBadge } from "bootstrap-vue";
</script>
